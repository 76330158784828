import "core-js/modules/es6.promise";
import "core-js/modules/es6.object.to-string";
import { Meteor } from 'meteor/meteor';
import { Tracker } from 'meteor/tracker';
export default (function () {
  return new Promise(function (resolve, reject) {
    Tracker.autorun(function (c) {
      // stop computation when Meteor.user() is ready
      Meteor.user() !== undefined && c.stop(); // return false if user is a guest

      Meteor.user() === null && resolve(false); // return true if user is logged in

      Meteor.user() && resolve(true);
    });
  });
});