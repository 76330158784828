/*
// import LocalStorage from 'quasar/src/plugins/LocalStorage.js';
import Vue from 'vue'

// import { contentNames } from 'api/collections'

const state = {
    content: {},
    introductie: {}
    // settings: {
    //     show12HourTimeFormat: false,
    //     showTasksInOneList: false
    // }
}

const mutations = {
    setContent (state, content) {
        console.log(content)
        // Object.assign(state.content, content)
        Object.assign(state.introductie, content)
        Vue.set(state.introductie, 'introductie', content)
    }
}

const actions = {
    // async printFiles () {
    //     const files = await getFilePaths();
    //
    //     await Promise.all(files.map(async (file) => {
    //         const contents = await fs.readFile(file, 'utf8')
    //         console.log(contents)
    //     }));
    // },
    async loadContent ({ commit }) {
        console.log('CALL: content#loadContent')
        // axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
        // axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        // const [introductie, zelfaandeslagVoedsel, zelfaandeslagNestelgelegenheid, testjson] = await Promise.all([

        const callArray = [Vue.prototype.$axios.get(`${process.env.API_URL}/content/introductie`)]
        // contentNames.forEach(contentName => {
        //     console.log('contentName', contentName)
        //     Vue.prototype.$axios.get(`${process.env.API_URL}/content/${contentName}`)
        // })
        // console.log('contentNames', contentNames)
        const response = await Promise.all([
            callArray
        ])
        // const response = await Promise.all([
        //     // Vue.prototype.$axios.get(`https://cdnbij.deontwikkelfabriek.nl/json/introductie.json?&timestamp=${new Date().getTime()}`),
        //     // Vue.prototype.$axios.get(`https://cdnbij.deontwikkelfabriek.nl/json/zelfaandeslag_voedsel.json`),
        //     // Vue.prototype.$axios.get(`https://cdnbij.deontwikkelfabriek.nl/json/zelfaandeslag_nestelgelegenheid.json`),
        //     // Vue.prototype.$axios.get(`https://03ff9fc0.eu.ngrok.io/images/1?timestamp=${new Date().getTime()}`)
        //     // Vue.prototype.$axios.get(`${process.env.CDN_URL}/images/1?timestamp=${new Date().getTime()}`)
        //     // Vue.prototype.$axios.get(`${process.env.API_URL}/content/introductie`)
        // ])
        // let content = {
        //     'introductie': introductie.data,
        //     'zelfaandeslagVoedsel': zelfaandeslagVoedsel.data,
        //     'zelfaandeslagNestelgelegenheid': zelfaandeslagNestelgelegenheid.data,
        //     'testjson': testjson.data
        // }

        // let content = {
        //     'introductie': introductie.data
        // }
        console.log('response', response)

        commit('setContent', response)

        // // Make third request using responses from the first two
        // const thirdResponse = await axios.get('https://maps.googleapis.com/maps/api/directions/json?origin=place_id:' + firstResponse.data.results.place_id + '&destination=place_id:' + secondResponse.data.results.place_id + '&key=' + 'API-KEY-HIDDEN');
    }
}

const getters = {
    content: state => {
        return state.content
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
*/