import "core-js/modules/es6.function.name";
import colors from 'quasar/src/utils/colors.js';;
var themes = {
  namespaced: true,
  state: {
    name: 'Light'
  },
  mutations: {
    SET_THEME: function SET_THEME(state, newTheme) {
      state.name = newTheme.name;
      colors.setBrand('primary', newTheme.primary);
      colors.setBrand('secondary', newTheme.secondary);
      colors.setBrand('accent', newTheme.accent);
    }
  },
  actions: {
    ChangeTheme: function ChangeTheme(context) {
      if (context.state.name === 'Dark') {
        context.commit('SET_THEME', lightTheme);
      } else {
        context.commit('SET_THEME', darkTheme);
      }
    }
  }
};
var darkTheme = {
  name: 'Dark',
  primary: '#222222',
  secondary: '#111111',
  accent: '#333333'
};
var lightTheme = {
  name: 'Light',
  primary: '#cce6ff',
  secondary: '#0052a3',
  accent: '#f57b00'
};
export default themes;