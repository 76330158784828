import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from "./routes";
Vue.use(VueRouter);
/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation
 */
// export default function (/* { store, ssrContext } */) {

export default function (_ref) {
  var store = _ref.store;
  var Router = new VueRouter({
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },

    /* scrollBehavior (to, from, savedPosition) {
        console.log(savedPosition)
        if (savedPosition) {
            return savedPosition
        } else if (to.hash) {
            return {
                selector: to.hash
            }
        }
    }, */
    routes: routes,
    // Leave these as is and change from quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    mode: process.env.VUE_ROUTER_MODE,
    base: process.env.VUE_ROUTER_BASE
  });
  Router.beforeEach(function (to, from, next) {
    store.dispatch('setShowToolbarState', {
      payload: true
    });
    next(); // if (to.matched.some(record => record.meta.showToolbar)) {
    //     if (store.getters['auth/isSignedIn']) {
    //         next()
    //     } else {
    //         next({ name: 'account-signin', query: { next: to.fullPath } })
    //     }
    // } else {
    //     next()
    // }
  });
  return Router;
}