import isMeteorUser from "../boot/meteor/isMeteorUser.js";
import { Meteor } from 'meteor/meteor';
var routes = [// {
//     path: '/',
//     component: () => import('pages/Index.vue')
// },
{
  path: '/',
  component: function component() {
    return import('layouts/MyLayout.vue');
  },
  children: [
  /* {
      path: '',
      props: true,
      component: () => import('pages/Timeline.vue'),
      beforeEnter: (to, from, next) => {
          isMeteorUser().then(response => {
              // console.log('ROUTES.js - Meteor.user().profile.owner', Meteor.user().profile.owner)
              // if true, continue, else redirect to Login page
              response ? next() : next({ name: 'welcome' })
          })
      }
  }, */
  {
    path: '',
    name: 'home',
    props: true,
    // component: () => import('pages/MyCommunityPage.vue'),
    beforeEnter: function beforeEnter(to, from, next) {
      isMeteorUser().then(function (response) {
        // console.log('ROUTES.js - Meteor.user().profile.owner', Meteor.user())
        // if true, continue, else redirect to Login page
        // response ? next() : next({ name: 'welcome' })
        // response ? (Meteor.user().profile.owner ? next({ name: 'myOwnedCommunities' }) : (Meteor.user().profile.subs.length > 0 ? next() : next({ name: 'myAreas' }))) : next({ name: 'welcome' })
        // response ? Meteor.user().profile.owner ? next({ name: 'myOwnedCommunities' }) : next() : next({ name: 'welcome' })
        if (response) {
          if (Meteor.user().profile.owner) {
            next({
              name: 'myOwnedCommunities'
            });
          } else {
            next({
              name: 'myCommunities'
            });
          }
        } else next({
          name: 'welcome'
        });
      });
    }
  }, {
    path: 'admin',
    name: 'admin',
    meta: {
      showToolbar: false
    },
    component: function component() {
      return import('pages/AdminPage.vue');
    }
  }, {
    path: 'welcome',
    name: 'welcome',
    meta: {
      showToolbar: false
    },
    component: function component() {
      return import('pages/Welcome.vue');
    },
    props: true
  }, {
    path: 'welcome/:id',
    name: 'welcome2',
    meta: {
      showToolbar: false
    },
    component: function component() {
      return import('pages/Welcome.vue');
    }
  }, {
    path: 'reset-password/:id',
    name: 'resetPassword',
    meta: {
      showToolbar: false
    },
    component: function component() {
      return import('pages/ResetPasswordPage.vue');
    },
    props: true
  }, // { path: 'timeline', name: 'timeline', props: true, component: () => import('pages/Timeline.vue') },
  {
    path: 'measureDetail',
    name: 'measureDetail',
    props: true,
    component: function component() {
      return import('pages/Timeline.vue');
    }
  }, // { path: 'communityDetail', name: 'communityDetail', props: true, component: () => import('pages/Timeline.vue') },
  {
    path: 'community/:id',
    name: 'communityDetail',
    component: function component() {
      return import('pages/Community.vue');
    },
    props: true
  }, {
    path: 'edit-community/:id/edit',
    name: 'editCommunity',
    props: true,
    component: function component() {
      return import('pages/EditCommunityPage.vue');
    }
  }, {
    path: 'create-community',
    name: 'createCommunity',
    props: true,
    component: function component() {
      return import('pages/CreateCommunityPage.vue');
    }
  }, {
    path: 'create-thread/:id',
    name: 'createThread',
    component: function component() {
      return import('pages/CreateThreadPage.vue');
    },
    props: true
  }, {
    path: 'edit-thread/:id',
    name: 'editThread',
    component: function component() {
      return import('pages/EditThreadPage.vue');
    },
    props: true
  }, {
    path: 'create-meeting/:id',
    name: 'createMeeting',
    component: function component() {
      return import('pages/CreateMeetingPage.vue');
    },
    props: true
  }, {
    path: 'send-invites/:id',
    name: 'sendInvites',
    component: function component() {
      return import('pages/SendInvitesPage.vue');
    },
    props: true
  }, {
    path: 'create-polis/:id',
    name: 'createPolis',
    component: function component() {
      return import('pages/CreatePolisPage.vue');
    },
    props: true
  }, {
    path: 'create-poll/:id',
    name: 'createPoll',
    component: function component() {
      return import('pages/CreatePollPage.vue');
    },
    props: true
  }, {
    path: 'create-pledge/:id',
    name: 'createPledge',
    component: function component() {
      return import('pages/CreatePledgePage.vue');
    },
    props: true
  }, {
    path: 'add-pledge/:id',
    name: 'addPledge',
    component: function component() {
      return import('pages/AddPledgePage.vue');
    },
    props: true
  }, {
    path: 'edit-pledge/:id',
    name: 'editPledge',
    component: function component() {
      return import('pages/EditPledgePage.vue');
    },
    props: true
  }, {
    path: 'add-treasure/:id',
    name: 'addTreasure',
    component: function component() {
      return import('pages/AddTreasurePage.vue');
    },
    props: true
  }, {
    path: 'my-areas',
    name: 'myAreas',
    component: function component() {
      return import('pages/MyAeraPage.vue');
    }
  }, {
    path: 'my-communities',
    name: 'myCommunities',
    component: function component() {
      return import('pages/MyCommunityPage.vue');
    },
    beforeEnter: function beforeEnter(to, from, next) {
      isMeteorUser().then(function (response) {
        // console.log('ROUTES.js - Meteor.user().profile.owner', Meteor.user())
        // if true, continue, else redirect to Login page
        // response ? next() : next({ name: 'welcome' })
        // response ? (Meteor.user().profile.owner ? next({ name: 'myOwnedCommunities' }) : (Meteor.user().profile.subs.length > 0 ? next() : next({ name: 'myAreas' }))) : next({ name: 'welcome' })
        // response ? Meteor.user().profile.owner ? next({ name: 'myOwnedCommunities' }) : next() : next({ name: 'welcome' })
        if (response) {
          if (Meteor.user().profile.owner) {
            next({
              name: 'myOwnedCommunities'
            });
          } else {
            next();
          }
        } else next({
          name: 'welcome'
        });
      });
    }
  }, {
    path: 'my-owned-communities',
    name: 'myOwnedCommunities',
    component: function component() {
      return import('pages/MyOwnedCommunityPage.vue');
    }
    /*
                    beforeEnter: (to, from, next) => {
                        isMeteorUser().then(response => {
                            // console.log('ROUTES.js - Meteor.user().profile.owner', Meteor.user())
                            // if true, continue, else redirect to Login page
                            // response ? next() : next({ name: 'welcome' })
    
                            // response ? (Meteor.user().profile.owner ? next({ name: 'myOwnedCommunities' }) : (Meteor.user().profile.subs.length > 0 ? next() : next({ name: 'myAreas' }))) : next({ name: 'welcome' })
    
                            // response ? Meteor.user().profile.owner ? next({ name: 'myOwnedCommunities' }) : next() : next({ name: 'welcome' })
                            if (response) {
                                if (Meteor.user().profile.owner) {
                                    next()
                                } else {
                                    next({ name: 'myCommunities' })
                                }
                            } else next({ name: 'welcome' })
                        })
                    }
    */

  }, {
    path: 'voedsel',
    name: 'voedsel',
    component: function component() {
      return import('pages/VoedselPage.vue');
    }
  }, {
    path: 'map',
    name: 'map',
    component: function component() {
      return import('pages/Map.vue');
    },
    props: true
  },
  /* { path: 'create-area', name: 'createMaatregel', component: () => import('pages/CreateAreaPage.vue') }, */

  /* {
      path: 'area/:id/edit',
      name: 'editArea',
      component: () => import('pages/EditAreaPage.vue')
  }, */
  {
    path: 'area/:id',
    name: 'area',
    component: function component() {
      return import('pages/AreaPage.vue');
    }
  }, {
    path: 'add-area',
    name: 'addAreaPage',
    component: function component() {
      return import('pages/AddAreaPage.vue');
    }
  }, {
    path: 'moderator-requests',
    name: 'moderatorRequests',
    component: function component() {
      return import('pages/ModeratorRequestsPage.vue');
    }
  }, // { path: 'introduction', name: 'introduction', component: () => import('pages/Introduction.vue') },
  {
    path: 'account',
    name: 'account',
    component: function component() {
      return import('pages/Account.vue');
    }
  }, {
    path: 'login',
    component: function component() {
      return import('pages/Login.vue');
    }
  }, {
    path: 'info',
    name: 'info',
    component: function component() {
      return import('pages/Info.vue');
    }
  }, {
    path: 'land-owner-register',
    name: 'landOwnerRegister',
    component: function component() {
      return import('pages/LandOwnerRegisterPage.vue');
    }
  }, {
    path: 'post/:id',
    name: 'post',
    props: true,
    component: function component() {
      return import('pages/PostPage.vue');
    }
  }, {
    path: 'meeting/:id',
    name: 'meeting',
    props: true,
    component: function component() {
      return import('pages/MeetingPage.vue');
    }
  }, {
    path: 'polis/:id',
    name: 'polis',
    props: true,
    component: function component() {
      return import('pages/PolisPage.vue');
    }
  }, {
    path: 'poll/:id',
    name: 'poll',
    props: true,
    component: function component() {
      return import('pages/PollPage.vue');
    }
  }, {
    path: 'pledge/:id',
    name: 'pledge',
    props: true,
    component: function component() {
      return import('pages/PledgePage.vue');
    }
  }, {
    path: 'debug',
    name: 'debug',
    component: function component() {
      return import('pages/DebugPage.vue');
    }
  }]
}, {
  path: '/survey',
  component: function component() {
    return import('layouts/SurveyLayout.vue');
  },
  children: [{
    path: 'introduction',
    name: 'introduction',
    component: function component() {
      return import('pages/Introduction.vue');
    }
  }, {
    path: 'home',
    name: 'setHome',
    component: function component() {
      return import('pages/SetHome.vue');
    }
  }, {
    path: 'create-area/:mode',
    name: 'createMaatregel',
    props: true,
    component: function component() {
      return import('pages/CreateAreaPage.vue');
    }
  }, {
    path: 'funding',
    name: 'funding',
    component: function component() {
      return import('pages/FundingPage.vue');
    },
    beforeEnter: function beforeEnter(to, from, next) {
      isMeteorUser().then(function (response) {
        if (response) {
          if (Meteor.user().profile.owner) {
            next({
              name: 'myOwnedCommunities'
            });
          } else {
            next();
          }
        } else next({
          name: 'welcome'
        });
      });
    }
  } // {
  //     path: 'create-area',
  //     name: 'createMaatregel',
  //     component: () => import('pages/CreateAreaPage.vue')
  // },
  ]
}]; // Always leave this as last one

if (process.env.MODE !== 'ssr') {
  routes.push({
    path: '*',
    component: function component() {
      return import('pages/Error404.vue');
    }
  });
}

export default routes;