import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from 'src/i18n';
import Quasar from 'quasar/src/vue-plugin.js';; // import { axiosInstance } from 'boot/axios.js'

Vue.use(VueI18n);
var i18nInstance = new VueI18n({
  locale: 'en-us',
  // fallbackLocale: 'en-us',
  messages: messages
});
export default (function (_ref) {
  var app = _ref.app;
  app.i18n = i18nInstance;
});
export { i18nInstance };
export var setLocale = function setLocale(locale) {
  i18nInstance.locale = locale; // axiosInstance.defaults.headers.common['Accept-Language'] = locale

  import("quasar/lang/".concat(locale)).then(function (lang) {
    Quasar.lang.set(lang.default);
  });
};