export default {
  messages: {
    // account_created: 'Please check your email to verify your registration. This sometimes can take up to 10 minutes. Please check the spam folder if the email has not been received.',
    // password_forgot: 'Please check your email to reset your password.',
    // verification_success: 'Your e-mail has been verified. You can now login.',
    // verification_failed: 'Verification has failed.',
    // password_recover_success: 'Your password has been reset. You can now login with the new password.',
    // logout_confirmation: 'Are you sure you want to log out?',
    // confirm: 'Confirm',
    // update_available: 'A new update is available. Click on ok to reload the page.',
    // account_signed_out: 'You have been signed out',
    // account_updated: 'Account succesfully updated'
    account_created: 'Account succesvol aangemaakt',
    password_forgot: 'Controlleer je e-mail voor het opnieuw instellen van je wachtwoord.',
    account_signed_out: 'Je bent succesvol uitgelogd',
    account_updated: 'Account succesvol aangepast',
    verification_success: 'Je e-mail is geverifieerd. Je kunt nu inloggen.',
    verification_failed: 'Verificatie is mislukt.',
    password_recover_success: 'Je wachtwoord is opnieuw ingesteld. Je kunt nu inloggen met je nieuwe wachtwoord.',
    logout_confirmation: 'Weet je zeker dat je uit wilt loggen?',
    login_confirmation: 'Je bent succesvol ingelogd. Welkom terug!',
    login_failed: 'E-mail en wachtwoord combinatie niet bekend',
    confirm: 'Bevestig',
    update_available: 'Een nieuwe update is beschikbaar. Klik op ok om de pagina te verversen.',
    url_copied_to_clipboard: 'De kaart url is gecopieerd naar het klembord',
    add_area_account_required: 'Om een gebied toe te kunnen voegen, heb je een account nodig.',
    area_deleted: 'Het gebied is verwijderd',
    area_updated: 'Het gebied is aangepast',
    unsubscribed: 'Je bent niet langer lid van deze community',
    subscribed: 'Je bent nu onderdeel van deze community',
    thread_created: 'Er is een nieuwe discussie gestart',
    thread_updated: 'De discussie is aangepast',
    comment_created: 'Je hebt content geplaatst',
    comment_reported: 'Deze content is gevlagd voor moderatie',
    comment_unreported: 'Deze content is vrijgegeven na moderatie',
    comment_deleted: 'Deze content is verwijderd',
    community_updated: 'Deze content is aangepast',
    RMS_send: 'Een moderator verzoek is verstuurd',
    poll_created: 'Poll aangemaakt',
    polis_created: 'Polis aangemaakt',
    meeting_created: 'Meeting aangemaakt',
    meeting_invite_accepted: 'Je hebt de uitnodiging geaccepteerd',
    meeting_invite_declined: 'Je hebt de uitnodiging geweigerd',
    pledge_funding_completed: 'Doneren succesvol afgerond',
    pledge_added: 'Bedankt voor je toezegging van € '
  },
  errors: {
    general_error: 'Er is een fout opgetreden. Probeer het nogmaals.',
    not_found: 'Sorry, hier is niks...',
    network: 'Netwerk fout. Controleer je internet verbinding',
    // fields: 'Please correct the input fields',
    required: 'Verplicht veld',
    register: {
      password_length: 'De minimale lengte van het wachtwoord is {length} karakters.',
      password_match: 'De wachtwoorden komen niet overeen.',
      email_valid: 'Een geldig e-mail adres is vereist',
      name_length: 'Scherm naam lengte moet tussen {minLength} en {maxLength} karakters zijn',
      organization_length: 'Organisatienaam lengte moet tussen {minLength} en {maxLength} karakters zijn'
    }
  },
  buttons: {
    pledge: 'Toezeggen',
    submit: 'Verstuur',
    logout: 'Log uit',
    cancel: 'Annuleren',
    login: 'Login',
    register: 'Verstuur',
    // go_back: 'Go back',
    send: 'Send',
    reSend: 'Verstuur opnieuw',
    close: 'Sluit',
    // previous: 'Previous',
    save: 'Opslaan',
    create: '',
    update: 'Update',
    delete: 'Verwijder',
    subscribe: 'Aanmelden',
    unsubscribe: 'Afmelden',
    next: 'Volgende',
    continue: 'Verder',
    back: 'Terug',
    add: 'Voeg toe',
    edit: 'Aanpassen',
    reply: 'Antwoorden',
    report: 'Melden',
    unreport: 'Melden annuleren',
    goto_authenticate: 'Naar login',
    stop: 'Stop',
    continue_register: 'Verder met registratie',
    continue_participation: 'Doorgaan met participatie',
    become_moderator: 'Moderator worden',
    add_image: 'Plaatje toevoegen',
    accept: 'Accepteren',
    decline: 'Weigeren'
  },
  headers: {
    login: 'Login',
    register: 'Registreren',
    password_forgot: 'Nieuw wachtwoord aanvragen',
    password_recover: 'Wachtwoord herstellen',
    administrator: 'Administrator',
    navigation: 'Navigatie',
    my_overview: 'Mijn overzicht',
    overview: 'Overzicht'
  },
  activities: {
    act_cycl: {
      title: 'Fietsen',
      desciption: 'TODO'
    },
    act_walk: {
      title: 'Wandelen of hardlopen',
      desciption: 'TODO'
    },
    act_tour: {
      title: 'Rondrijden met auto of motor',
      desciption: 'TODO'
    },
    act_natu: {
      title: 'Natuur observeren',
      desciption: 'TODO'
    },
    act_water: {
      title: 'Watersport',
      desciption: 'TODO'
    },
    act_play: {
      title: 'Spelen of zitten',
      desciption: 'TODO'
    },
    act_wintr: {
      title: 'Wintersport',
      desciption: 'TODO'
    },
    act_mount: {
      title: 'Bergport',
      desciption: 'TODO'
    }
  },
  labels: {
    account_required: 'Account noodzakelijk',
    read_more: 'lees verder',
    'area_create_button': 'Opslaan',
    'area_update_button': 'Opslaan',
    'area_created_succes': 'Gebied succesvol aangemaakt',
    'area_updated_succes': 'Gebied succesvol aangepast',
    'community_created_succes': 'Community succesvol aangemaakt',
    agree_disclaimer: 'Ik ga akkoord met deze voorwaarden',
    my_account: 'Mijn account',
    register: 'Maak account aan',
    logout: 'Loguit',
    login: 'Login',
    email: 'E-mailadres',
    name: 'Scherm naam',
    companyName: 'Bedrijfsnaam',
    organization: 'Organisatienaam',
    sector: 'Sector',
    address: 'Adres',
    address_search: 'Adres',
    // zipcodeCompany: 'Zipcode headquarter',
    website: 'Web pagina',
    city: 'Woonplaats',
    // cityCompany: 'City headquarter',
    age: 'Leeftijd',
    education: 'Opleiding',
    gender: 'Gender',
    type: 'Type',
    password: 'Wachtwoord',
    repeat_password: 'Herhaal wachtwoord',
    remember_me: 'Onthoud mij',
    password_forgot: 'Wachtwoord vergeten?',
    home: 'Thuis',
    here: 'hier',
    neighbourhood: 'buurt',
    'living-area': 'leefomgeving',
    country: 'land',
    worldwide: 'wereldwijd',
    share_this_area: 'Deel dit gebied',
    share_this_community: 'Deel deze community',
    subscribed: 'Geabonneerd',
    reply: 'Antwoorden',
    comment: 'Commentaar',
    finish: 'Beeindigen',
    readmore: 'lees meer',
    search: 'zoek',
    created_on: 'aangemaakt op'
  },
  pages: {
    moderators: {
      title: 'Moderators',
      mod_requests: 'Moderator verzoeken',
      mods: 'Moderators'
    },
    create_community: {
      title: 'Maak community'
    },
    land_owner_register: {
      title: 'Land-eigenaar registratie',
      steps: {
        general: {
          nav_title: 'Algemen info',
          title: 'Landeigenaar informatie',
          task_title: 'Geef enige algemene informatie over het land dat u bezit/beheert',
          email: 'E-mail'
        },
        draw: {
          nav_title: 'Kaart',
          title: 'Teken gebied',
          task_title: 'Teken uw gebied'
        },
        description: {
          nav_title: 'Omschrijving',
          title: 'Beschrijf uw community',
          task_title: 'Geef een titel die uw community beschrijft',
          input_title: 'Titel',
          input_title_error: 'Maximaal 50 karakters',
          input_description: 'Omschrijving',
          task_description: 'Beschrijf wat juist deze community aantrekkelijk, waardevol of belangrijk maakt',
          input_description_error: 'Maximaal 1000 karakters'
        },
        finalize: {
          nav_title: 'Afronden',
          title: 'Informatie',
          task_title: 'Dank voor het verstrekken van deze informatie, als u op Afronden klikt dan kunne nwe uw community aanmaken. Om te discussieren met uw community kunt u een discussie aanmaken. Kik op de 3 puntjes rechts van \'discussie\' om een nieuwe discussie aan te maken.'
        }
      }
    },
    pledge: {
      count: 'Aantal toezeggingen',
      part1: 'toegezegd van',
      part2: 'doel'
    },
    add_pledge: {
      title_nav: 'Toezeggen',
      title: 'Geef het bedrag aan dat u zou willen toezeggen voor het financieren van dit project.',
      amount: 'Bedrag',
      reason: 'Zou u alstublieft willen toelichten welk bedrag u heeft gekozen? Waarom heeft u voor dit bedrag gekozen?'
    },
    add_treasure: {
      title_nav: 'Maak treasure aan',
      photos: 'Fotos',
      title: 'Upload foto van treasures',
      description: 'Beschrijf waarom je deze plek mooi vind. Deel een tekening, gedicht, afbeelding of een kort verhaal.',
      upload: 'Upload foto (.jpg)'
    },
    create_pledge: {
      title_nav: 'Maak toezegging aan',
      title: 'Beschrijf uw financieringsverzoek met een pakkende titel.',
      description: 'Beschrijf uw financieringsverzoek in meer detail. Geef achtergrondinformatie, voorgestelde acties, verwachte resultaten en wat uw donateurs ervoor terugkrijgen.',
      type: 'Pledge type',
      fixed: 'Vast',
      fixed_desc: 'Verzoek om eenmalige financiering',
      yearly: 'Jaarlijks',
      yearly_desc: 'Verzoek om jaarlijks terugkerende financiering',
      '2yearly': 'Per 2 jaar',
      '2yearly_desc': 'Verzoek om financiering die elke 2 jaar terugkeert',
      '3yearly': 'Per 3 jaar',
      '3yearly_desc': 'Verzoek om financiering die elke 3 jaar terugkeert',
      '4yearly': 'Per 4 jaar',
      '4yearly_desc': 'Verzoek om financiering die elke 4 jaar terugkeert',
      '5yearly': 'Per 5 jaar',
      '5yearly_desc': 'Verzoek om financiering die elke 5 jaar terugkeert',
      goal: 'Geef uw streefbedrag aan in euro\'s'
    },
    edit_pledge: {
      title_nav: 'Aanpassen pledge'
    },
    create_thread: {
      title_nav: 'Maak discussie aan',
      title_nav_edit: 'Pas discussie aan',
      title: 'Geef een titel',
      description: 'Geef eerste aanzet voor de discussie',
      options: 'Voeg poll opties toe',
      option: 'optie',
      upload: 'Upload fotos (.jpg)'
    },
    create_poll: {
      title_nav: 'Maak Poll aan',
      title_nav_edit: 'Pas Poll aan',
      title: 'Geef een titel',
      description: 'Geef eerste aanzet voor de discussie',
      upload: 'Upload fotos (.jpg)'
    },
    create_polis: {
      title_nav: 'Maak polis aan',
      title_nav_edit: 'Pas Polis aan',
      title: 'Geef een titel',
      id: 'Geef het polis ID op',
      voter_option: 'Alleen leden van de community kunnen stemmen'
    },
    create_meeting: {
      title_nav: 'Maak Meeting aan',
      title_nav_edit: 'Pas Meeting aan',
      title: 'Geef een titel',
      description: 'Geef een omschrijving',
      url: 'Geef externe url van de online bijeenkomst',
      dateTime: 'Starttijd & datum'
    },
    meeting: {
      total_subs: 'Totaal aantal leden',
      accepted_invites: 'Geaccepteerde uitnodigingen',
      rejected_invites: 'Geweigerde uitnodigingen',
      planned_label: 'gepland op',
      invited_banner: 'Je bent uigenodigd voor deze meeting',
      accepted_banner: 'Je hebt de uitnodiging geaccepteerd, als je geselecteerd word ontvang je een e-mail met verdere details over de meeting.',
      rejected_banner: 'Je hebt de uitnodiging geweigerd',
      send_banner: 'Stuur de link voor de meeting naar de leden die de uitnodiging hebben geaccepteerd.',
      resend_banner: 'Stuur de link voor de meeting opnieuw. Let op: je hebt de url reeds verstuurd!'
    },
    landing: {
      my_portal: 'My Xena'
    },
    welcome: {
      title: 'Welkom bij Greenmapper',
      introduction: 'Introductie',
      paragraph_1: 'In Greenmapper Friends kun je aantrekkelijke, waardevolle of voor jou belangrijke natuurlijke plekken markeren. Deze gebieden moeten plekken met groen, water of natuur zijn. Je kunt denken aan een plek in een park, bij een meer, op zee, in een bos, een grasland, een bloemenveld, een plek om vogels te kijken, etc. Het kan een plek zijn binnen of buiten een stad of dorp, klein of groot. Je kunt het vaak bezoeken of wellicht nooit! Zo lang het maar een natuurlijke plek is die je aantrekkelijk, waardevol of betekenisvol is voor jou.',
      subtitle_2: 'Waarom registreren?',
      paragraph_2: 'The idea is that you enter your favourite natural areas that you want to be a friend of. For that reason we ask you a couple of questions to create an account for you. Your emailaddress is not shown in the app, only your (screen)name.',
      subtitle_3: 'Privacy policy',
      paragraph_3: 'Greenmapper Friends is een initiatief van de Universiteit van Groningen. Greenmapper Friends slaat je e-mailadres op in de Greenmapper Landschape Friend Registry (GLFR). Niemand toegang tot dit e-mail behalve de Greenmapper organisatie. Via Friends ben je bereikbaar voor landeigenaren, land beheerders, of andere friends van het gebied op anonieme wijze. Deze derde partijen krijgen namelijk nooit rechtstreeks tot je e-mailadres. We volgen de AVG en GDPR regels ten aanzien van je privacy de opslag van persoonlijke data.',
      continue_without_account: 'Doorgaan zonder account',
      login_text: 'Als je al een account hebt, kun je inloggen',
      forgot_password: 'Wachtwoord vergeten?',
      forgot_password_dialog: 'Geef je e-mailadres.'
    },
    introduction: {
      title: 'Introductie',
      text: 'In Greenmapper Friends kun je aantrekkelijke, waardevolle of voor jou belangrijke natuurlijke plekken markeren. Deze gebieden moeten plekken met groen, water of natuur zijn. Je kunt denken aan een plek in een park, bij een meer, op zee, in een bos, een grasland, een bloemenveld, een plek om vogels te kijken, etc. Het kan een plek zijn binnen of buiten een stad of dorp, klein of groot. Je kunt het vaak bezoeken of wellicht nooit! Zo lang het maar een natuurlijke plek is die je aantrekkelijk, waardevol of betekenisvol is voor jou.',
      start_btn: 'Start enquête'
    },
    register: {
      disclaimer_title: 'Disclaimer Greenmapper Friends',
      disclaimer: 'Greenmapper Friends stores your email address in the Greenmapper Landscape Friend Registry (GLFR). No one can access this email accept Greenmapper Friends. You can via the GLFR be reached anonymously by land-owners, land management, or other friends of the area. But these third parties do not have direct access to your email or personal data. We also aks your home location. We only do this to show you a map of your surroundings by default. We follow the European General Data Protection Regulation guidelines for privacy and the storage of your personal data. Read more details'
    },
    info_contact: {
      title: 'Over ons',
      contact: 'Contact',
      disclaimer_title: 'Disclaimer',
      implementation: 'Implementatie: '
    },
    map: {
      title: 'Algemene community kaart',
      add_btn: 'Teken een nieuw gebied',
      share_title: 'Deel kaart url',
      share_btn: 'Kopieer naar klembord',
      hint_on_add: 'Plaats de stip op de kaart om te starten met tekenen',
      hint_on_end_draw: 'Je kunt het gebied nog steeds aanpassen.',
      zoom_to_area_btn: 'Zoom in naar het gebied',
      details_btn: 'Bekijk details' // share_desc: 'Share'

    },
    timeline: {
      title: 'Tijdlijn',
      hint: 'Wist je dat de meest populaire activiteit op favoriete natural plekken wandelen is?',
      rating: 'Waardering',
      rating_subtext: 'Persoonlijke waardering van het gemarkeerde gebied',
      visit_frequency: 'Bezoekfrequentie',
      visit_frequency_subtext: 'Hoe vaak bezoek je het gebied`',
      activities: 'Activiteiten',
      activities_subtext: 'Activiteiten gemeld bij gemarkeerde gebied',
      add_btn: 'Voeg gebied toe',
      edit_btn: 'Pas gebied aan',
      delete_btn: 'Verwijder gebied',
      delete_title: 'Verwijder gebied',
      delete_subtitle: 'Weet je het zeker?'
    },
    add_area_info: {
      title: 'Voeg een gebied toe',
      add_btn: 'Voeg gebied toe'
    },
    my_areas: {
      title: 'Mijn gebieden',
      sub_title: 'Alle natuurlijke plekken die ik aantrekkelijk, waardevol of belangrijk vind.',
      created_areas: 'Gemarkeerder gebieden',
      joint_surface: 'Totaal oppervlak (km2)'
    },
    my_communities: {
      title: 'Mijn communities',
      sub_title: 'Alle gebieden van landeigenaren of land beheerder die overlappen met mijn gebieden. Hier ben je bereikbaar als vriend van die gebieden.',
      delete_btn: 'Afmelden',
      delete_title: 'Afmelden',
      delete_subtitle: 'Weet je zeker dat je je wilt afmelden voor deze community?',
      befriended_communities: 'Bevriende communities',
      joint_surface: 'Totaal oppervlak (km2)'
    },
    info: {
      text1: 'Greenmapper Friends wordt ontwikkeld door Greenmapper. Voor meer informatie kijk op Greenmapper.org',
      text2: 'Als je vragen hebt of in contact wilt komen met ons, stuur dan een e-mail naar: info@greenmapper.org',
      text3: 'We proberen de Greenmapper Friends applicatie en data accuraat en vrij van fouten te houden. Als je -ondanks onze inspanningen- incomplete of inacurate informatie tegenkomt, dan kunnen we daar geen verantwoordelijkheid voor nemen.',
      text4: 'We houden het recht voor om aanpassingen, het verwijderen of toevoegen van materiaal aan de inhoud van de applicatie te doen zonder waarschuwing vooraf. Greenmapper Friends accepteert geen aansprakelijkheid voor informatie op websites waarnaar wij verwijzen via hyperlinks.'
    },
    my_owned_communities: {
      title: 'Mijn communities',
      owned_communities: 'Communities',
      total_subs: 'Totaal aantal leden',
      joint_surface: 'Totaal oppervlak (km2)'
    },
    community: {
      report_title: 'Melden commentaar',
      report_subtitle: 'Weet je zeker dat je dit commentaar wilt melden voor moderatie?',
      report_hint: 'Geef een reden',
      delete_title: 'Verwijder commentaar',
      delete_subtitle: 'Weet je zeker dat je dit commentaar wilt verwijderen?',
      discussions_title: 'Discussies',
      discussions_subtitle: 'Open discussies over elk onderwerp',
      poll_title: 'Poll',
      poll_subtitle: 'Simpele opiniepeiling',
      polis_title: 'Pol.is',
      polis_subtitle: 'Geavanceerde interactieve opiniepeiling met stellingen',
      pledges_title: 'Toezeggingen',
      pledges_subtitle: 'Virtuele toezegging van geld voor biodiversiteit, landschapskwaliteit of duurzame diensten',
      meetings_title: 'Meetings',
      meetings_subtitle: 'Diepgaande online workshop of vergadering van vrienden',
      RMS_dialog_title: 'Verzoek moderator status',
      RMS_dialog_text: 'Wil je een verzoek versturen om moderator voor deze community te worden?'
    },
    account: {
      edit_title: 'Pas profiel aan',
      edit_text: 'Pas je huidige profiel informatie aan',
      edit_btn: 'Pas profiel aan',
      signout_title: 'Log uit',
      signout_text: 'Uitloggen van je account',
      signout_btn: 'Log uit'
    },
    create_area: {
      title: 'Gebied aanmaken',
      home_title: 'Plaats thuis marker',
      stop_title: 'Pauzeer registratie en maak later af',
      stepper: {
        description: 'Omschrijving',
        rate: 'Beoordeling',
        visit_freq: 'Bezoekfrequentie',
        activities: 'Activiteiten',
        connected: 'Online verbonden'
      },
      steps: {
        level: {
          nav_title: 'Niveau',
          title: 'Kies niveau',
          task_title: 'Kies niveau van het gebied',
          options: {
            neighborhood: 'buurt / lokaal',
            living_area: 'leefomgeving / regionaal',
            country: 'land',
            world: 'wereld'
          }
        },
        map: {
          nav_title: 'Kaart',
          title: 'Teken gebied',
          task_title: 'Teken je gebied'
        },
        description: {
          nav_title: 'Omschrijving',
          title: 'Omschrijving',
          task_title: 'Geef een titel die het gebied weergeeft',
          input_title: 'Titel',
          input_title_error: 'Maximaal 50 karakters',
          input_description: 'Omschrijving',
          task_description: 'Omschrijf in je eigen woorden wat gebied aantrekkelijk, waardevol of belangrijk voor je maakt',
          input_description_error: 'Maximaal 1000 karakters'
        },
        rate: {
          nav_title: 'Beoordeling',
          title: 'Waardeer je gebied',
          task_title: '(Score: 1 = slecht, 6 = gemiddeld, 10 = perfect)'
        },
        visit_freq: {
          nav_title: 'Bezoekfrequentie',
          title: 'Hoe vaak bezoek je jouw gebied?',
          options: {
            daily: 'Dagelijks',
            weekly: 'Wekelijks',
            monthly: 'Maandelijks',
            few_times_a_year: 'Paar keer per jaar',
            yearly: 'Jaarlijks',
            rarely: 'Soms',
            never: 'Nooit'
          }
        },
        activities: {
          nav_title: 'Activiteiten',
          title: 'Welke activiteiten doe je zoal in dit gebied?'
        },
        priority: {
          nav_title: 'Prioriteit',
          title: 'Deze plek zou moeten worden:',
          options: {
            maintained: 'Behouden (= zo laten als het is)',
            strengthened: 'Versterkt (= verbeteren)'
          }
        },
        relationship: {
          nav_title: 'Relatie',
          title: 'Wat is uw relatie met dit favoriete natuurgebied?',
          options: {
            tourist: 'Toerist',
            inhabitant: 'Inwoner',
            work: 'Werkgerelateerd'
          }
        }
      },
      _edit: {
        title: 'Pas gebied aan'
      }
    },
    edit_area: {
      title: 'Pas gebied aan'
    },
    funding: {
      title: 'Virtual Nature & Community funds',
      nav_intro: 'Introductie',
      nav_amount: 'Hoeveelheid',
      nav_locations: 'Locaties',
      nav_objectives: 'Doeleinden',
      nav_finalize: 'Einde',
      intro1: 'Geachte deelnemer,',
      intro2: 'Bedankt voor uw deelname aan ons Greenmapper-experiment.',
      intro3: 'Hartelijk dank voor uw deelname aan ons Greenmapper-financieringsexperiment. U bent een liefhebber van verschillende gebieden, zoals bijvoorbeeld Nationaal Park Duinen van Texel. De duurzame ontwikkeling van deze gebieden kan extra financiële ondersteuning vereisen. Een wereldwijd knelpunt is langdurige financiering voor de natuur, zoals het behoud van biodiversiteit, een aantrekkelijk landschapsaangezicht, het duurzaam maken van bedrijfsactiviteiten in uw favoriete gebied en voor culturele activiteiten. Hiervoor hebben we voor elk van uw favoriete natuurgebieden een virtueel investeringsfonds samengesteld. We noemen deze fondsen “Virtual Nature & Community funds”. Zo kunt u een (voor dit experiment fictief) steentje bijdragen aan het gebied waar u met zo veel plezier komt. Een Virtual Nature & Community fund ziet er als volgt uit:',
      intro4: 'De (virtuele) gelden voor één gebied komen terecht in één fonds. Hier worden ze, naar aanleiding van uw verdeling, onderverdeeld in categorieën. Vervolgens worden de gelden per categorie verdeeld over specifieke projecten. Dus, alhoewel de gelden zich in één fonds bevinden, zijn ze specifiek toegewezen aan categorieën en projecten. We zijn benieuwd hoe u een budget zou verdelen tussen de Virtual Nature & Community funds van uw favoriete gebieden. Bent u eerder geneigd om te geven aan het behoud van biodiversiteit, een aantrekkelijk landschapsaangezicht, het verduurzamen van bedrijfsactiviteiten, cultuur, of iets anders?',
      intro5: 'Het experiment duurt ongeveer 5-8 minuten en uw antwoorden worden anoniem verwerkt. Gebruik alstublieft alleen de ‘terug’-knop linksonder om één stap terug te gaan. Door op de pijl linksboven te klikken worden uw antwoorden gewist. Neem de tijd om de vragen te beantwoorden en wees eerlijk. Er zijn geen goede of foute antwoorden. Uw geanonimiseerde antwoorden zijn waardevol voor academisch onderzoek. U kunt ervoor kiezen uw gegevens uit te sluiten zonder enige consequenties. Voor verdere vragen kunt u contact met mij opnemen via e-mail (j.beverdam@rug.nl)l. Door deel te nemen aan deze studie over natuurfinanciering stemt u in met de verwerking van uw geanonimiseerde persoonlijke gegevens voor onderzoeksdoeleinden.',
      amount1: 'Virtueel financieringsbedrag',
      amount2: 'Allereerst vragen we u om na te denken over een mogelijk budget dat u bereid zou zijn te besteden aan alle Virtual Nature & Community funds in totaal. Let op dat dit volledig virtueel en hypothetisch is, maar we sporen u aan om een zo realistisch mogelijk budget te kiezen. Kies een virtueel, maar realistisch jaarlijks budget.',
      amount3: 'Kies een virtueel maar realistisch jaarlijks budget.',
      amount4: 'Zou u alstublieft willen toelichten welk virtueel bedrag u heeft gekozen? Waarom heeft u voor dit bedrag gekozen?',
      amount5: 'jaarlijks budget',
      max: 'Maximun bedrag 5000 euro',
      min: 'Minimun bedrag 1 euro',
      locations1: 'Budget verdeling over de Virtual Nature & Community Funds van uw favoriete natuurgebieden',
      locations2: 'Geef alstublieft aan hoe u uw gekozen budget zou verdelen over de Virtual Nature & Community funds van uw favoriete natuurgebieden.',
      locations3: 'Zou u alstublieft uw gekozen verdeling over de Virtual Nature & Community funds van uw favoriete natuurgebieden willen toelichten? Waarom heeft u deze verdeling gekozen?',
      objectives1: 'Budget verdeling over de financieringsdoeleinden',
      objectives2: 'Uw verdeling aan de Virtual Nature & Community funds voor elk van uw gebieden wordt hier per afzonderlijk gebied weergegeven. Geeft u alstublieft voor elk van uw favoriete gebieden (lokaal, regionaal, nationaal, wereldwijd) aan hoe u het toegewezen budget zou verdelen over de vier financieringsdoelen: behoud van biodiversiteit, een aantrekkelijk landschapsaangezicht, het ondersteunen van verduurzaming in bedrijven in uw favoriete gebied, of cultuur. Als u iets wilt financieren wat niet binnen deze categorieën valt, kunt u dit onderaan beschrijven en er financiering aan toewijzen.',
      objectives3: 'Zou u alstublieft uw gekozen verdeling willen toelichten? Waarom heeft u deze verdeling gekozen?',
      biodiversity_title: 'Behoud van biodiversiteit',
      biodiversity_text: 'Een rijke biodiversiteit ondersteunt het functioneren van ecosystemen en is cruciaal voor het ondersteunen van al het leven op aarde. Projecten voor biodiversiteitsbehoud hebben als doel om de biodiversiteitswaarden te verbeteren of te behouden, vaak in een lokale context. Dergelijke projecten kunnen gericht zijn op het verbeteren van de bodem- en waterkwaliteit of bijvoorbeeld het plaatsen van nestkasten voor vogels.',
      ALC_title: 'Aantrekkelijk landschapsaangezicht',
      ALC_text: 'Een aantrekkelijk landschapsaangezicht heeft betrekking op het uitzicht en het genieten van een landschap. Een dergelijk project kan gaan over het beschermen of herstellen van landschapselementen zoals heggen, het opnieuw ‘verwilderen’ van het landschap of het aanleggen van wandel- of fietsroutes door het landschap.',
      SUSBUS_title: 'Duurzaam ondernemerschap',
      SUSBUS_text: 'Duurzame bedrijven voeren hun economische activiteiten uit zonder negatieve effecten voor hun (lokale) omgeving te veroorzaken. Duurzame bedrijfsprojecten kunnen betrekking hebben op de overgang van een conventionele boerderij naar biologische of natuurinclusieve landbouw. Het kan ook verwijzen naar het stichten van een netwerk van lokale bedrijven en organisaties die op geïntegreerde wijze afval en vervuiling verminderen.',
      finalize1: 'Hartelijk dank voor uw deelname aan deze studie over de financiering van natuur, uitgevoerd door de Rijksuniversiteit Groningen. Uw antwoorden zijn voor ons erg waardevol. Klik alstublieft op de knop \'opslaan\' om uw antwoorden vast te leggen.',
      finalize1A: 'U wordt nu doorgestuurd naar de Greenmapper Friends-community voor Nationaal Park Duinen van Texel voor het derde deel van het experiment. Hier hebben we zes financieringsverzoeken ontwikkeld en geplaatst met betrekking tot echte uitdagingen voor projecten op Texel. Lees deze verzoeken zorgvuldig door en overweeg of u deze projecten financieel zou ondersteunen, of waarom niet. U kunt voor elk verzoek een toezegging indienen en uw toelichting geven.',
      finalize2: 'Houd er rekening mee dat het mogelijk is om uw ingediende gegevens zonder gevolgen in te trekken. Als u dit wenst te doen of andere vragen heeft over het onderzoek, stuur dan een e-mail naar',
      cultural_title: 'Culturele projecten',
      cultural_text: 'Natuurgebieden kunnen ook een cultureel erfgoed of andere culturele aspecten bevatten. Bijvoorbeeld als er historische gebouwen of monumenten aanwezig zijn, of als er bepaalde culturele evenementen mee verbonden zijn. Culturele projecten hebben betrekking tot de organisatie van (muzikale) evenementen, educatieve excursies of het behoud van landgoederen.',
      other_title: 'Anders',
      other_text: 'Wilt u iets anders financieren in uw favoriete natuurgebied, maar werd dit hierboven niet genoemd? Beschrijft u dit alstublieft zelf! U kunt deze schuifregelaar gebruiken om aan te geven hoeveel van uw budget u eraan wilt toewijzen. Dit is niet verplicht, alleen als u denkt aan iets dat niet binnen de eerder genoemde financieringsdoelen valt.',
      placeholder: 'Anders namelijk..'
    }
  },
  drawer: {
    area_section: 'Ontdekken',
    my_section: 'Mijn overzicht',
    other_section: 'Anders'
  },
  gender: {
    male: 'man',
    female: 'vrouw',
    other: 'anders'
  },
  education: {
    1: 'Basisonderwijs',
    2: 'Middelbaar onderwijs',
    3: 'Opleiding zonder diploma',
    4: 'MBO',
    5: 'HBO',
    6: 'Universitaire Bachelor',
    7: 'Universitaire Master',
    8: 'Doctoraat'
  }
};